<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-select
                v-model="formData.type"
                :items="types"
                class="ph-textbox"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="formData.type === 'DEP'">
              <v-autocomplete
                v-model="formData.currencyId"
                :items="collections.currencies"
                class="ph-textbox"
                label="Currency"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="formData.type === 'DEP'">
              <v-autocomplete
                v-model="formData.bank"
                :items="banks"
                item-text="displayText"
                class="ph-textbox"
                label="Bank"
                :rules="requiredRules"
                return-object
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="formData.type === 'DEP'">
              <v-text-field
                v-model="formData.referenceNumber"
                class="ph-textbox"
                label="Reference Number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="formData.type === 'DEP'">
              <v-text-field
                v-model="formData.amount"
                class="ph-textbox"
                label="Amount"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="6" v-if="formData.type === 'DEP'">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6" v-if="formData.type === 'DEP'">
              <v-btn @click="saveData" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col :cols="12" v-if="formData.type === 'WRL'">
        <v-data-table
          :headers="headers"
          :items="items"
          class="custom-table"
          calculate-widths
        >
          <template #item.action="{ item }">
            <v-btn @click="showWithdrawPrompt(item)" class="font-weight-bold" color="primary" block small text>
              Withdraw
            </v-btn>
          </template>
        </v-data-table>
        <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
          Cancel
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isPromptOpened"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Withdraw
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header text-center">
          Are you sure you want to proceed?
          <br>
          <v-text-field
            v-model="selectedItem.newAmount"
            label="Actual Amount"
            type="number"
            hide-spin-buttons
            v-if="selectedItem"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeWithdrawPrompt"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="withdraw"
            class="ph-action-button"
            :disabled="selectedItem && !selectedItem.newAmount"

          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      items: 'replenishBranch/items'
    })
  },
  data () {
    return {
      isPromptOpened: false,
      selectedItem: null,
      banks: [],
      requiredRules: [v => !!v || 'This field is required'],
      dateRules: [
        v => !!v || 'This field is required',
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'
      ],
      formData: {
        type: 'DEP',
        currencyId: '',
        bank: null,
        referenceNumber: '',
        amount: ''
      },
      headers: [
        {
          text: 'Date of Transaction',
          sortable: true,
          value: 'trx_date',
          width: '150px'
        },
        {
          text: 'Bank Branch',
          sortable: true,
          value: 'bank_branch',
          width: '150px'
        },
        {
          text: 'Reference No.',
          sortable: true,
          value: 'reference_no',
          width: '150px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount',
          width: '150px'
        },
        {
          text: 'Action',
          sortable: true,
          value: 'action',
          width: '80px'
        }
      ],
      breadcrumbsItems: [
        {
          text: 'BOS',
          disabled: true
        },
        {
          text: 'Replenish Branch',
          disabled: false,
          exact: true,
          to: '/replenish-branch'
        },
        {
          text: 'New Entry',
          disabled: true
        }
      ],
      types: [
        { text: 'DEPOSIT', value: 'DEP' },
        { text: 'WITHDRAWAL', value: 'WRL' }
      ]
    }
  },
  methods: {
    async withdraw () {
      if (this.selectedItem) {
        if (parseFloat(this.selectedItem.newAmount) > 0 && parseFloat(this.selectedItem.newAmount) <= parseFloat(this.selectedItem.amount)) {
          try {
            const { code } = await this.$store.dispatch('replenishBranch/updateData', {
              trx_bank_transaction_id: this.selectedItem.trx_bank_transaction_id,
              status: '2',
              trx_date: this.currUser.trx_date,
              user_id: this.currUser.user_id,
              type: this.selectedItem.type,
              location_id: this.currUser.location_id,
              locations_bank_id: this.selectedItem.locations_bank_id,
              currency_id: this.selectedItem.currency_id,
              amount: this.selectedItem.newAmount,
              reference_no: this.selectedItem.reference_no,
              client_ip: this.currUser.ip_address,
              terminal_id: '0',
              expense_type_id: this.selectedItem.expense_type_id,
              particulars: this.selectedItem.particulars
            })

            if (code === 200) {
              renderToast('success', 'Successful', 'Withdrawal transaction has been processed')
              this.closeWithdrawPrompt()
              this.retrieveData()
            } else {
              renderToast('error', 'Request Error', 'Failed to withdraw')
            }
          } catch (e) {
            renderToast('error', 'USP Error', 'Failed to withdraw')
          }
        } else {
          renderToast('warning', 'Validation Error', 'Actual amount can\'t be zero or higher the bank amount')
        }
      }
    },
    closeWithdrawPrompt () {
      this.selectedItem = null
      this.isPromptOpened = false
    },
    showWithdrawPrompt (item) {
      this.selectedItem = item
      this.isPromptOpened = true
    },
    async saveData () {
      if (this.$refs.form.validate()) {
        try {
          const { code } = await this.$store.dispatch('replenishBranch/saveData', {
            location_id: this.currUser.location_id,
            locations_bank_id: this.formData.bank?.locations_bank_id,
            type: 'DEP',
            currency_id: this.formData.currencyId,
            amount: this.formData.amount,
            reference_no: this.formData.referenceNumber,
            date_transaction: this.currUser.trx_date,
            encoded_by: this.currUser.user_id,
            client_ip: this.currUser.ip_address,
            terminal_id: this.currUser.fs_id,
            trx_date: this.currUser.trx_date
          })

          if (code === 200) {
            renderToast('success', 'Successful', 'Deposit has been saved')
            this.cancel()
          } else {
            renderToast('error', 'Request Error', 'Failed saving the given data')
          }
        } catch (e) {
          renderToast('error', 'USP Error', 'Failed saving the given data')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/replenish-branch')
    },
    async retrieveData () {
      await this.$store.dispatch('replenishBranch/retrieveData', {
        trxDate: this.currUser.trx_date,
        locationId: this.currUser.location_id,
        type: 'W'
      })
    }
  },
  async beforeMount () {
    const { code, result } = await this.$store.dispatch('replenishBranch/getBankList', {
      locationId: this.currUser.location_id
    })

    if (code === 200) {
      this.banks = result.map(item => {
        return {
          ...item,
          displayText: `${item.location_name} - ${item.bank_branch} (${item.account_number})`
        }
      })
    } else {
      renderToast('error', 'USP Error', 'Failed fetching the required data')
    }

    this.retrieveData()
  }
}
</script>
