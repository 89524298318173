export default {
  currentPage: state => state.currentPage,
  currentStep: state => state.currentStep,
  policies: state => state.policies,
  successData: state => state.successData,
  selectedPremium: state => state.selectedPremium,
  transactionData: state => state.transactionData,
  insuranceList: state => state.insuranceList,
  ctplList: state => state.ctplList,
  agentPrefixList: state => state.agentPrefixList,
  rsoPrefixList: state => state.rsoPrefixList
}
