<template>
  <v-row class="px-2 py-2 px-sm-4 py-sm-4 px-md-6 py-md-6" no-gutters>
    <v-col :cols="12">
      <h3 class="custom-heading pt-2 pb-2 primary--text font-weight-black">
        Results ({{ searchData.results.length }})
      </h3>
    </v-col>
    <v-col :cols="12" class="pb-4 pb-md-6">
      <v-btn-toggle :value="currentView" @change="updateCurrentView">
        <v-btn small>Grid View</v-btn>
        <v-btn small>List View</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col :cols="12" v-if="currentView === 0">
      <v-row no-gutters>
        <v-col
          class="d-flex px-0 px-sm-1 px-md-2 pb-2 pb-md-3"
          :cols="12"
          :sm="4"
          :md="2"
          v-for="result in searchData.results"
          :key="result.customer_id"
        >
          <v-card @click="doSelectUpdate(result)" class="custom-card mx-auto px-sm-2">
            <v-card-title class="px-0 mx-0 py-2">
              <v-spacer />
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list class="px-0 py-1">
                  <v-list-item @click="doUpdate(result)">
                    <v-icon class="mr-1" small>mdi-pencil</v-icon>
                    Update
                  </v-list-item>
                  <v-list-item v-if="!isNotCustomerPage" class="red--text" @click="confirmDelete(result.customer_id)">
                    <v-icon class="mr-1" color="red" small>mdi-delete</v-icon>
                    Archive
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text class="px-1">
              <v-row no-gutters>
                <v-col class="d-flex flex-row justify-center" :cols="12">
                  <v-avatar size="70px" :style="avatarColor(result.full_name)">
                    <span class="white--text text-h4">{{ avatarInitial(result.full_name) }}</span>
                  </v-avatar>
                </v-col>
                <v-col class="pt-3 pb-3" :cols="12">
                  <h4 class="customer-name">
                    {{ toProperCase(result.first_name) }} {{ toProperCase(result.last_name) }}
                  </h4>
                </v-col>
                <v-col class="pt-2 pb-1" :cols="12">
                  <v-row no-gutters>
                    <v-col class="px-1 text-right" :cols="4">
                      <span class="caption pr-1">CRN</span>
                    </v-col>
                    <v-col class="px-1" :cols="8">
                      <strong>{{ result.customer_number || '-' }}</strong>
                    </v-col>
                    <v-col class="px-1 text-right" :cols="4">
                      <span class="caption pr-1">Birth</span>
                    </v-col>
                    <v-col class="px-1" :cols="8">
                      <strong>{{ result.birth_date || '-' }}</strong>
                    </v-col>
                    <v-col class="px-1 text-right" :cols="4">
                      <span class="caption pr-1">Mobile</span>
                    </v-col>
                    <v-col class="px-1" :cols="8">
                      <strong>{{ result.mobile_no || '-' }}</strong>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="pt-2" :cols="12" v-if="searchData.results.length === 0">
          <span class="caption">
            No customers found
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="12" class="px-0 px-sm-1 px-md-2 pb-2 pb-md-3" v-if="currentView === 1">
      <v-data-table
        class="ph-clickable-table"
        :headers="listViewHeaders"
        :items="searchData.results"
        @click:row="doSelectUpdate"
        no-data-text="No customers found"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
            <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
          </v-avatar>
          {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
        </template>
        <template v-slot:[`item.gender`]="{ item }">
          {{ toProperCase(item.gender) || '-' }}
        </template>
        <template v-slot:[`item.mobile_no`]="{ item }">
          {{ item.mobile_no || '-' }}
        </template>
        <template v-slot:[`item.LastTransaction`]="{ item }">
          {{ item.LastTransaction || '-' }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="d-none d-sm-block"
                icon
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="px-0 py-1">
              <v-list-item @click="doUpdate(item)">
                <v-icon class="mr-1" small>mdi-pencil</v-icon>
                Update
              </v-list-item>
              <v-list-item v-if="!isNotCustomerPage" class="red--text" @click="confirmDelete(item.customer_id)">
                <v-icon class="mr-1" color="red" small>mdi-delete</v-icon>
                Archive
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="d-block d-sm-none">
            <v-btn @click="doUpdate(item)" class="mx-1" color="primary" text>
              <v-icon class="mr-1" small>mdi-pencil</v-icon>
              Edit
            </v-btn>
            <v-btn @click="confirmDelete(item.customer_id)" class="mx-1" color="red" text>
              <v-icon class="mr-1" small>mdi-delete</v-icon>
              Archive
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  props: {
    isNotCustomerPage: Boolean
  },
  data () {
    return {
      isNonex: false,
      listViewHeaders: [
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'CRN',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        },
        {
          text: 'Last Transaction',
          sortable: true,
          value: 'LastTransaction'
        },
        {
          text: '',
          sortable: true,
          value: 'action'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      searchData: 'customers/searchData',
      currentView: 'customers/currentView'
    })
  },
  watch: {
    isNotCustomerPage: {
      handler (value) {
        this.isNonex = value
        console.log(value)
      }
    },
    deep: true
  },
  methods: {
    updateCurrentView (val) {
      this.$store.commit('customers/SET_CURRENT_VIEW', val)
    },
    doSelectUpdate (customerDetails) {
      if (this.isNotCustomerPage) {
        this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
        this.renderToast('info', 'Customer Selected!', `${customerDetails.full_name} has been successfully selected.`)
      } else {
        this.doUpdate(customerDetails)
      }
    },
    doUpdate (customerDetails) {
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: '/customers'
        }
      })
    },
    confirmDelete (customerID) {
      const confirmDelete = confirm('Do you want to archive this customer?')

      if (confirmDelete) {
        this.doDelete({
          userID: this.currUser.user_id,
          customerID
        })
      }
    },
    ...mapActions({
      doDelete: 'customers/doDelete'
    }),
    renderToast,
    avatarColor,
    avatarInitial,
    toProperCase
  }
}
</script>
