export default {
  currentPage: 0,
  currentStep: 1,
  policies: [],
  successData: null,
  selectedPremium: null,
  transactionData: {
    formNumber: '',
    insurance: '',
    policy: '',
    inceptionDate: '',
    expiryDate: '',
    agentCode: '',
    agentPrefix: '',
    rsoCode: '',
    rsoPrefix: '',
    amount: '',
    insuredCustomerID: '',
    insuredFirstName: '',
    insuredMiddleName: '',
    insuredNoMiddleNameTag: false,
    insuredLastName: '',
    insuredBirthDate: '',
    insuredOccupation: '',
    insuredMobileNumber: '',
    insuredEmailAddress: '',
    insuredPensionCode: '',
    beneficiaryFirstName: '',
    beneficiaryMiddleName: '',
    beneficiaryNoMiddleNameTag: false,
    beneficiaryLastName: '',
    beneficiaryRelationship: '',
    mvType: '',
    premiumType: '',
    plateNumber: '',
    noPlateNumber: false,
    ORCRMonth: '',
    vehicleYear: '',
    vehicleMake: '',
    vehicleFileNumber: '',
    vehicleChassisNumber: '',
    vehicleEngineNumber: '',
    vehicleColor: '',
    vehicleUnladenWeight: '',
    vehicleBody: '',
    vehicleAuthorizedCapacity: '',
    vehicleBasicPremium: '',
    vehicleAuthenticationFee: '',
    vehicleDocStamps: '',
    vehicleCancellationFee: '',
    vehicleVAT: '',
    vehicleLGT: '',
    vehicleTotalCommission: '',
    vehiclePetnetCommission: '',
    vehicleBPCommission: '',
    vehicleDiscountedPrice: '',
    vehiclePromoCode: '',
    vehicleIsCorporate: false,
    vehicleCompanyName: '',
    policyType: ''
  },
  insuranceList: [
    {
      category: 'COC',
      logo: 'accident.svg',
      name: 'Accident Protect'
    },
    {
      category: 'COC3',
      logo: 'dengue.svg',
      name: 'Dengue Protect Plus'
    },
    // {
    //   category: 'COC4',
    //   logo: 'payapa.svg',
    //   name: 'Pera Payapa'
    // },
    // {
    //   category: 'COC6',
    //   logo: 'covid.svg',
    //   name: 'COVID Protect Plus'
    // },
    {
      category: 'COC7',
      logo: 'ctpl.svg',
      name: 'CTPL'
    },
    {
      category: 'COC8',
      logo: 'dengue2.svg',
      name: 'Dengue Protect v2'
    }
  ],
  agentPrefixList: [
    'RSO',
    'SPA',
    'PA'
  ],
  rsoPrefixList: [
    'ROH',
    'RSO'
  ],
  ctplList: []
}
