import api from '@/api'

export default {
  grid (trxDate, locationID) {
    return api.get(`/v1/transactions/api/trx/pera-protect/grid-all?trx_date=${trxDate}&location_id=${locationID}`)
  },
  validate (firstName, lastName, birthDate, category, inceptionDate, expiryDate) {
    return api.get(`/v1/transactions/api/trx/insurance/validate?first_name=${firstName}&last_name=${lastName}&birthdate=${birthDate}&category=${category}&inceptiondate=${inceptionDate}&expirydate=${expiryDate}`)
  },
  save (payload) {
    const endpoints = {
      COC: 'malayan',
      COC3: 'dengue-protect',
      COC4: 'eternal-plans',
      COC6: 'covid-protect',
      COC7: 'confirmation',
      COC8: 'dengue-protect-v2'
    }

    if (payload.category === 'COC7') {
      return api.post(`/v1/ctpl/${endpoints[payload.category]}`, payload)
    } else {
      console.log(payload)
      return api.post(`/v1/insurance/wrapper/api/${endpoints[payload.category]}`, payload)
    }
  },
  policies (payload) {
    return api.post('/v1/maintenance/api/insurance/policies', payload)
  },
  accidentPlusPolicies (payload) {
    return api.get('/v1/maintenance/api/insurance/accident-plus-policies')
  },
  requestAdjustment (payload) {
    return api.post('/v1/transactions/api/maintenance/adjustment/life-insurance', payload)
  },
  ctplList () {
    return api.get('/v1/transactions/api/maintenance/price-commission-ctpl')
  },
  signedCOCList (payload) {
    return api.post('/v1/maintenance/api/return-all-filtered-coc', payload)
  }
}
