import api from '@/api'

export default {
  sourceOfFund () {
    return api.get('/v1/maintenance/api/collections/source-of-fund')
  },
  purposeOfTransaction (remcoID) {
    let endpoint = '/v1/maintenance/api/collections/purpose-of-transaction'

    if (remcoID && remcoID !== 0) endpoint = `${endpoint}?remco_id=${remcoID}`

    return api.post(endpoint)
  },
  occupation (remcoID) {
    let endpoint = '/v1/maintenance/api/collections/occupation'

    if (remcoID && remcoID !== 0) endpoint = `${endpoint}?remco_id=${remcoID}`

    return api.post(endpoint)
  },
  relationship () {
    return api.get('/v1/maintenance/api/collections/relationship')
  },
  relationshipdppv2 () {
    return api.get('/v1/maintenance/api/dppv2-relationship')
  },
  idList (remcoID = 0) {
    return api.get(`/v1/maintenance/api/collections/id-list?remco_id=${remcoID}`)
  },
  countries () {
    return api.get('/v1/maintenance/api/collections/countries')
  },
  d2bcorridors () {
    return api.get('/v1/maintenance/api/collections/d2b-corridors')
  },
  wudascountries (payload) {
    return api.post('/v1/remit/wu/wu/collection/wudas', payload)
  },
  natureOfWork () {
    return api.get('/v1/maintenance/api/collections/nature-of-work')
  },
  positionLevel () {
    return api.get('/v1/maintenance/api/collections/position-level')
  },
  employment () {
    return api.get('/v1/maintenance/api/collections/employment')
  },
  cities () {
    return api.get('/v1/maintenance/api/maintenance/city')
  },
  barangay (city) {
    return api.get(`/v1/maintenance/api/maintenance/barangay/${city}`)
  },
  d2bcountries () {
    return api.get('/v1/maintenance/api/collections/d2b-countries')
  },
  locations (locationType) {
    return api.get(`/v1/maintenance/api/locations-all?location_type=${locationType}`)
  },
  modules (payload) {
    return api.post('/v1/maintenance/api/user-management/retrieve-module', payload)
  },
  qpCompanies () {
    return api.get('/v1/maintenance/api/collections/qp-companies')
  },
  qpStateCities (countryCode) {
    return api.get(`/v1/maintenance/api/collections/statelist?country_code=${countryCode}`)
  },
  stateAndCities (countryCode) {
    return api.get(`/v1/maintenance/api/collections/statelist?country_code=${countryCode}`)
  },
  qrCode (locationID) {
    return api.get(`/v1/maintenance/api/staging/generate-qr/${locationID}`)
  },
  providers () {
    return api.get('/v1/maintenance/api/trxlimit/providers')
  }
}
