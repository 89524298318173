import { endpoints } from '@/api/endpoints'

const insurance = endpoints.use('insurance')

export default {
  getGrid: ({ commit }, { trxDate, locationID }) => new Promise((resolve, reject) => {
    insurance.grid(trxDate, locationID)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  validate: ({ commit }, { firstName, lastName, birthDate, category, inceptionDate, expiryDate }) => new Promise((resolve, reject) => {
    insurance.validate(firstName, lastName, birthDate, category, inceptionDate, expiryDate)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  save: ({ commit }, payload) => new Promise((resolve, reject) => {
    insurance.save(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getPolicies: ({ commit }, payload) => new Promise((resolve, reject) => {
    insurance.policies(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getAccidentPlusPolicies: ({ commit }) => new Promise((resolve, reject) => {
    insurance.accidentPlusPolicies()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  requestAdjustment: ({ commit }, payload) => new Promise((resolve, reject) => {
    insurance.requestAdjustment(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getCTPLList: ({ commit }) => new Promise((resolve, reject) => {
    insurance.ctplList()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getSignedCOCList: ({ commit }, payload) => new Promise((resolve, reject) => {
    insurance.signedCOCList(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  resetForm ({ state, commit }) {
    commit('SET_CURRENT_PAGE', 0)
    commit('SET_CURRENT_STEP', 1)
    commit('SET_POLICIES', [])
    commit('SET_SUCCESS_DATA', null)
    commit('SET_SELECTED_PREMIUM', null)

    Object.keys(state.transactionData).forEach(prop => {
      let value = ''

      if (prop === 'insuredNoMiddleNameTag' || prop === 'beneficiaryNoMiddleNameTag') value = false
      if (prop === 'noPlateNumber' || prop === 'vehicleIsCorporate') value = false

      commit('SET_TRANSACTION_DATA', { prop, value })
    })
  }
}
