<template>
  <div class="px-md-4 mt-3">
    <strong v-if="transactionData.insurance.category === 'COC7'">
      Customer Information
    </strong>
    <strong v-else>
      Insured Information
    </strong>
    <v-form ref="insured">
      <v-row class="mt-6 mb-md-5" no-gutters>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredFirstName"
            class="ph-textbox"
            label="First Name"
            readonly
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredMiddleName"
            class="ph-textbox"
            label="Middle Name"
            readonly
            outlined
          />
          <v-spacer />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.insuredNoMiddleNameTag"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Middle Name"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
              readonly
            />
          </div>
        </v-col>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredLastName"
            class="ph-textbox"
            label="Last Name"
            readonly
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredBirthDate"
            class="ph-textbox"
            label="Birth Date"
            readonly
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC4' && transactionData.insurance.category !== 'COC7'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.insuredOccupation"
            :items="collections.occupation"
            item-text="occupation"
            item-value="occupation_value"
            :return-object="false"
            class="ph-textbox"
            label="Occupation"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC4' || transactionData.insurance.category === 'COC7'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredMobileNumber"
            class="ph-textbox"
            label="Mobile Number"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7' || transactionData.insurance.category === 'COC'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredEmailAddress"
            class="ph-textbox"
            label="Email Address"
            outlined
          />
        </v-col>
        <v-col
          v-if="transactionData.insurance.category === 'COC' || transactionData.insurance.category === 'COC6'"
          class="pr-md-2 pb-3 pb-md-8"
          :cols="12"
          :md="6"
        >
          <v-text-field
            v-model="transactionData.insuredPensionCode"
            class="ph-textbox"
            label="Pension Association Code"
            outlined
          />
        </v-col>
      </v-row>
    </v-form>
    <strong v-if="transactionData.insurance.category === 'COC7'">
      Vehicle Information
    </strong>
    <strong v-else>
      Beneficiary Information
    </strong>
    <v-form ref="insured">
      <v-row class="mt-6 mb-md-5" no-gutters>
        <v-col v-if="transactionData.insurance.category !== 'COC7'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiaryFirstName"
            @input="convertToProperCase('beneficiaryFirstName')"
            class="ph-textbox"
            label="First Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiaryMiddleName"
            @input="convertToProperCase('beneficiaryMiddleName')"
            :disabled="transactionData.beneficiaryNoMiddleNameTag"
            class="ph-textbox"
            label="Middle Name"
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.beneficiaryNoMiddleNameTag"
              @change="toggleNoMiddleName"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Middle Name"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiaryLastName"
            @input="convertToProperCase('beneficiaryLastName')"
            class="ph-textbox"
            label="Last Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.beneficiaryRelationship"
            :items="computedRelationships"
            item-text="relationship"
            item-value="relationship"
            :return-object="false"
            class="ph-textbox"
            label="Relationship to Insured"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleFileNumber"
            @input="toUpperCase('vehicleFileNumber')"
            class="ph-textbox"
            label="MV File Number *"
            :rules="mvFileNumberRules"
            type="number"
            hide-spin-buttons
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleChassisNumber"
            @input="toUpperCase('vehicleChassisNumber')"
            class="ph-textbox"
            label="Chassis Number *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleEngineNumber"
            @input="toUpperCase('vehicleEngineNumber')"
            class="ph-textbox"
            label="Engine Number *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleYear"
            class="ph-textbox"
            label="Year *"
            :rules="requiredRules"
            type="number"
            hide-spin-buttons
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleMake"
            @input="convertToProperCase('vehicleMake')"
            class="ph-textbox"
            label="Make and Model/Series *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleColor"
            @input="convertToProperCase('vehicleColor')"
            class="ph-textbox"
            label="Color *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleBody"
            @input="convertToProperCase('vehicleBody')"
            class="ph-textbox"
            label="Type of Body *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleCompanyName"
            @input="convertToProperCase('vehicleCompanyName')"
            class="ph-textbox"
            label="Company Name *"
            :rules="companyNameRules"
            :disabled="!transactionData.vehicleIsCorporate"
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.vehicleIsCorporate"
              @change="onIsCoporateChange"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="Registered under Company"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
    <div class="mt-6 d-flex justify-space-between">
      <v-btn
        @click="back"
        class="rounded-lg font-weight-bold"
        color="primary"
        outlined
        large
      >
        Back
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toProperCase, trim } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      collections: 'collections',
      transactionData: 'insurance/transactionData'
    }),
    computedRelationships () {
      return this.transactionData.insurance.category === 'COC8' ? this.collections.relationshipdppv2 : this.collections.relationship
    }
  },
  data () {
    return {
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      mvFileNumberRules: [
        v => !!v || 'This field is required',
        v => (v.length === 15) || 'Must be 15 characters'
      ],
      companyNameRules: [
        v => ((this.transactionData.vehicleIsCorporate && !!v) || (!this.transactionData.vehicleIsCorporate && !v)) || 'This field is required'
      ]
    }
  },
  methods: {
    toUpperCase (prop) {
      this.transactionData[prop] = this.transactionData[prop].toUpperCase()
    },
    convertToProperCase (field) {
      this.transactionData[field] = toProperCase(trim(this.transactionData[field]))
    },
    onIsCoporateChange () {
      this.transactionData.vehicleCompanyName = ''
      this.$refs.insured.resetValidation()
    },
    back () {
      this.$store.commit('insurance/SET_CURRENT_STEP', 1)
    },
    toggleNoMiddleName (val) {
      if (val) this.transactionData.beneficiaryMiddleName = ''
    }
  }
}
</script>
