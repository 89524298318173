<template>
  <v-card class="ph-nonex-card">
    <v-form ref="searchForm" @submit.prevent="searchTransaction">
      <v-card-title>
        <h1 color="primary" class="ph-nonex-card-title">Search Transaction</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="ph-nonex-receive-search">
          <v-col cols="12" sm="2" class="ph-nonex-receive-search-title">
            <p class="ph-textbox-title">Control Number</p>
          </v-col>
          <v-col cols="12" sm="10" class="ph-nonex-receive-search-textbox">
            <v-text-field
              v-model="control_number"
              class="ph-textbox"
              single-line
              outlined
              placeholder="Input the Control Number here"
              persistent-hint
              :rules="requiredField"
              @keyup.enter="searchTransaction"
              hint="<p><b class='ph-textbox-hint-bold'>Note:</b> Do not include spaces, dashes and special characters when inserting a Control Number.</p><i>E.g XXXXXXXXXXXX0001</i>"
            >
              <template v-slot:message="{ message }">
                <span class="ph-textbox-hint" v-html="message"></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <br>
        <v-row class="ph-nonex-receive-search ph-nonex-receive-checkbox">
          <v-col cols="12" sm="2"></v-col>
          <v-col cols="12" sm="10">
            <v-checkbox
              v-model="isGCashTransaction"
            >
              <template v-slot:label>
                Check this if the transaction is&nbsp;<b>GCash Payout</b>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <br>
        <v-row class="ph-nonex-receive-search">
          <v-col cols="12" sm="2" class="ph-nonex-receive-search-title">
            <p class="ph-textbox-title">Amount</p>
            <!-- <p class="ph-textbox-title">Check this if the transaction is <b>GCash Payout</b></p> -->
          </v-col>
          <v-col cols="12" sm="10" class="ph-nonex-receive-search-textbox">
            <v-text-field
              v-model="amount"
              class="ph-textbox"
              single-line
              outlined
              placeholder="Place Amount Here"
              persistent-hint
              :rules="isGCashTransaction ? requiredField : []"
              @keyup.enter="searchTransaction"
              type="number"
              :disabled="!isGCashTransaction"
              :hide-spin-buttons="true"
              hint="<p class='control-number-instruction' style='font-size: 12px;'><b class='ph-textbox-hint-bold'>Please make sure that the control number and amount values provided are correct before inquiring the transaction.</b></p><i>Frequent searching of invalid transaction details may cause your branch to get suspended on GCash Payout API</i>"
            >
              <template v-slot:message="{ message }">
                <span class="ph-textbox-hint" v-html="message"></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <br>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="ph-nonex-receive-action-buttons">
            <v-btn
              color="danger"
              outlined
              text
              class="ph-primary-button ph-action-button"
              @click="cancelTransaction"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              elevation="0"
              class="ph-primary-button ph-primary-execute-btn"
              @click="searchTransaction"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <br>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { Buffer } from 'buffer/'
import { getForeignRefNo, getNonexRefNumber, renderToast } from '@/utils'

export default {
  name: 'ReceiveMoneySearch',
  data () {
    return {
      control_number: '',
      foreignRefNo: '',
      nonexRefNumber: '',
      isGCashTransaction: false,
      amount: '',
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      isGCashTrx: 'nonex/isGCashTrx'
    })
  },
  watch: {
    isGCashTransaction: {
      handler (val) {
        this.$store.commit('nonex/SET_IS_GCASH_TRX', val === true)
      },
      deep: true
    }
  },
  mounted () {
    this.foreignRefNo = this.getForeignRefNo()
    this.nonexRefNumber = this.getNonexRefNumber()
    this.$store.commit('nonex/SET_REFERENCE_NUMBER', this.nonexRefNumber)

    console.log(this.nonexRefNumber)

    if (this.$route.query.control_number) {
      this.control_number = this.$route.query.control_number
    }
  },
  methods: {
    getForeignRefNo,
    getNonexRefNumber,
    renderToast,
    clearStates () {
      this.$store.commit('nonex/SET_INQUIRE_RESULT', [])
      this.$store.commit('nonex/SET_CURRENT_TRANSACTION', {})
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
      this.$store.commit('customers/SET_SEARCH_RESULT', null)
    },
    searchTransaction () {
      /*
        References:
        $body['international_partner_code'] = "PL0005";
        $body['agent_code'] = $ceb_agent_code ? $ceb_agent_code  : "000";
        $body['branch_code'] = str_pad(Session::get('userinfo')[0]['plocation_id'], 5, '0', STR_PAD_LEFT);
        $body['location_code'] = "PERAHUB-". $user_info[0]['vlocation_code'];
      */
      this.clearStates()

      const validate = this.$refs.searchForm.validate()
      const generateDeviceId = `${this.currUser.user_id}${this.currUser.location_id}`
      let branchCode = ''

      branchCode = String('00000' + this.currUser.location_id).slice(-5)

      if (validate) {
        const payload = {
          location_name: this.currUser.location_name,
          reference_number: this.nonexRefNumber,
          control_number: this.control_number,
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          branch: this.currUser.location_name,
          international_partner_code: 'PL0005',
          agent_code: this.currUser.location_code,
          agent_id: '84424911',
          branch_code: branchCode,
          // additional for rcbc
          outlet_code: `PERAHUB-${this.currUser.location_code}`,
          client_reference_no: this.nonexRefNumber,
          location_code: `PERAHUB-${this.currUser.location_code}`,
          // location_code: 'PERAH-00001',
          currency: 'PHP',
          device_id: Buffer.from(generateDeviceId).toString('base64')
        }

        // const payload = {
        //   location_name: 'Aguirre',
        //   reference_number: this.nonexRefNumber,
        //   control_number: this.control_number,
        //   location_id: this.currUser.location_id + '2',
        //   user_id: this.currUser.user_id,
        //   branch: 'Aguirre',
        //   international_partner_code: 'PL0005',
        //   agent_code: '01010701',
        //   agent_id: '84424911',
        //   branch_code: branchCode,
        //   location_code: `PERAHUB1-${this.currUser.location_code}`,
        //   currency: 'PHP',
        //   device_id: Buffer.from(generateDeviceId).toString('base64')
        // }

        if (this.isGCashTransaction) {
          payload.payout_amount = this.amount

          if (this.amount < 1) {
            this.renderToast('error', 'Search Error', 'Amount must be greater than 0.')
          } else {
            this.$store.dispatch('nonex/gcashInquireTrx', payload)
          }
        } else {
          this.$store.dispatch('nonex/getInquireResult', payload)
        }
      } else {
        this.renderToast('error', 'Search Error', 'Control Number is required')
      }
    },
    cancelTransaction () {
      this.clearStates()
      this.$router.push('/pera-remit/receive')
    }
  }
}
</script>
<style scoped>
  .ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-nonex-receive-search-title {
    display: flex;
  }

  .ph-textbox-title {
    margin: auto;
    margin-left: 0;
    font-family: 'Proxima Nova Bold';
    font-size: 16px;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
  }

  .ph-nonex-receive-action-buttons {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
  }

  .ph-nonex-receive-checkbox {
    margin-top: 20px;
  }

  @media only screen and (min-width: 600px) {
    .ph-nonex-receive-action-buttons {
      flex-direction: row;
    }

    .ph-primary-button {
      width: 160px;
    }
  }
</style>
