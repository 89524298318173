<template>
  <div v-if="showForm" ref="receiveForm">
    <v-card class="ph-nonex-card">
      <v-card-title>
        <h1 color="primary" class="ph-nonex-card-title">Payout Transaction</h1>
      </v-card-title>
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3>Transaction Details</h3>
            </v-col>
            <v-row fluid class="ph-nonex-fields-container">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="transactionData.sender_name"
                  :rules="requiredField"
                  class="ph-textbox"
                  outlined
                  label="Sender Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="transactionData.receiver_name"
                  class="ph-textbox"
                  outlined
                  label="Receiver name"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  v-model="transactionData.control_number"
                  class="ph-textbox"
                  outlined
                  label="Control Number"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>Receiver Details</h3>
            </v-col>
            <v-row fluid class="ph-nonex-fields-container">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="transactionData.customer_name"
                  class="ph-textbox"
                  outlined
                  label="Customer Name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  v-model="transactionData.relation_to"
                  label="Relationship to Sender"
                  :items="collections.relationship"
                  item-text="relationship"
                  item-value="relationship"
                  :return-object="false"
                  :rules="requiredField"
                  color="gray darken-1"
                  class="ph-textbox"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  v-model="transactionData.purpose_transaction"
                  label="Purpose of Transaction"
                  :items="collections.purposeOfTransaction"
                  item-text="purpose"
                  item-value="purpose"
                  :return-object="false"
                  :rules="requiredField"
                  color="gray darken-1"
                  class="ph-textbox"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12" md="4" v-if="selectedTrxData.remco_id === 7">
                <v-autocomplete
                  v-model="transactionData.receiver_occupation_id_tf"
                  :items="collections.occupation"
                  label="Occupation"
                  item-text="occupation"
                  item-value="occupation_value"
                  :return-object="false"
                  :rules="requiredField"
                  color="gray darken-1"
                  class="ph-textbox"
                  autofocus
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12" md="4" v-if="selectedTrxData.remco_id === 20">
                <v-autocomplete
                  v-model="transactionData.receiver_occupation_id"
                  :items="unitellerOccupation"
                  label="Occupation"
                  item-text="CODE"
                  :item-value="CODE"
                  :return-object="false"
                  :rules="requiredField"
                  color="gray darken-1"
                  class="ph-textbox"
                  autofocus
                  outlined
                />
              </v-col>
            </v-row>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>ID Details</h3>
            </v-col>
            <v-row class="ph-nonex-fields-container ph-nonex-receive-id-container">
              <v-col class="ph-nonex-receive-id-picture" cols="12" md="4">
                <v-img
                  :src="getStoragePath({ usp2: 'true', img: currentIdFile })"
                  lazy-src="@/assets/images/grey.jpg"
                  max-width="500"
                  max-height="300"
                  class="ph-nonex-receive-id"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col class="ph-nonex-receive-id-details" cols="12" md="8">
                <v-row fluid>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="transactionData.id_type"
                      label="ID Type"
                      :items="collections.idList"
                      item-text="id_type"
                      item-value="id_type"
                      :return-object="false"
                      :rules="requiredField"
                      color="gray darken-1"
                      class="ph-textbox"
                      outlined
                      @change="syncIdInfo($event)"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="transactionData.id_number"
                      class="ph-textbox"
                      outlined
                      label="ID Number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="issueDatePicker"
                      v-model="issueDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ph-textbox"
                          color="gray darken-1"
                          label="ID Date of Issue"
                          append-icon="mdi-calendar"
                          hide-details
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          v-model="transactionData.id_date_of_issue"
                        />
                      </template>
                      <v-date-picker
                        v-model="transactionData.id_date_of_issue"
                        :active-picker.sync="activeIssueDatePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="syncIssueDate"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="expiryDatePicker"
                      v-model="expiryDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ph-textbox"
                          color="gray darken-1"
                          label="ID Date of Expiry"
                          append-icon="mdi-calendar"
                          hide-details
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          v-model="transactionData.id_expiration_date"
                        />
                      </template>
                      <v-date-picker
                        v-model="transactionData.id_expiration_date"
                        :active-picker.sync="activeExpiryDatePicker"
                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        @change="syncExpiryDate"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="transactionData.id_issued_by"
                      label="Issued by Country"
                      :items="collections.countries"
                      item-text="country"
                      item-value="country"
                      :return-object="false"
                      :rules="requiredField"
                      color="gray darken-1"
                      class="ph-textbox"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>Amount Details</h3>
            </v-col>
            <v-row fluid class="ph-nonex-fields-container">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="transactionData.currency"
                  class="ph-textbox"
                  outlined
                  label="Currency"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="transactionData.originating_country"
                  label="Country of Origin"
                  :items="collections.countries"
                  item-text="country"
                  item-value="country"
                  :return-object="false"
                  :rules="requiredField"
                  color="gray darken-1"
                  class="ph-textbox"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="transactionData.principal_amount"
                  class="ph-textbox"
                  outlined
                  label="Principal Amount"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="transactionData.total_amount"
                  class="ph-textbox"
                  outlined
                  label="Total Amount"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="ph-nonex-receive-action-buttons">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                elevation="0"
                class="ph-primary-button ph-primary-execute-btn"
                @click="confirmPayout"
              >
                Payout
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <br>
      </v-form>
    </v-card>
    <ReceiveMoneyConfirm v-bind:showConfirmDialog.sync="showConfirmDialog" v-bind:transactionData="transactionData" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import CryptoJS from 'crypto-js'
import ReceiveMoneyConfirm from '@/components/nonex/ReceiveMoneyConfirm.vue'
import { Buffer } from 'buffer/'
import { renderToast, aesDecrypt } from '@/utils'

export default {
  name: 'ReceiveMoneyForm',
  data () {
    return {
      activeIssueDatePicker: null,
      issueDatePicker: false,
      activeExpiryDatePicker: null,
      expiryDatePicker: false,
      showForm: false,
      customerData: {},
      selectedTrxData: {},
      transactionData: {},
      currentIdFile: '',
      valid: false,
      showConfirmDialog: false,
      requiredField: [
        v => !!v || 'This field is required'
      ],
      unitellerOccupation: []
    }
  },
  components: {
    ReceiveMoneyConfirm
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      selectedCustomer: 'customers/selectedCustomer',
      payoutFormData: 'nonex/payoutFormData',
      currentTrx: 'nonex/currentTrx',
      currUser: 'auth/currUser',
      payoutReferenceNo: 'nonex/payoutReferenceNo'
    })
  },
  watch: {
    expiryDatePicker (val) {
      val && setTimeout(() => (this.activeExpiryDatePicker = 'YEAR'))
    },
    selectedCustomer: {
      handler (data) {
        console.log(data)
        if (data) {
          this.customerData = data
          this.populatePayload(data)
          this.showForm = true
        } else {
          this.showForm = false
        }
      },
      deep: true
    },
    currentTrx: {
      handler (data) {
        console.log(data)
        this.selectedTrxData = data
      },
      deep: true
    },
    showConfirmDialog: {
      handler (data) {
        console.log(data)
      },
      deep: true
    },
    collections: {
      handler (data) {
        console.log(data)
      },
      deep: true
    }
  },
  beforeMount () {
    [
      'getSourceOfFund',
      'getPurposeOfTransaction'
    ].forEach(action => this.$store.dispatch(action))
  },
  mounted () {
    if (this.selectedCustomer !== null) {
      this.customerData = this.selectedCustomer
      this.populatePayload(this.customerData)
      this.showForm = true
    }
  },
  methods: {
    renderToast,
    syncIssueDate (date) {
      this.$refs.issueDatePicker.save(date)
    },
    syncExpiryDate (date) {
      this.$refs.expiryDatePicker.save(date)
    },
    syncIdInfo (idValue) {
      const currentIds = JSON.parse(this.selectedCustomer.img)

      const getId = this.collections.idList.filter(item => {
        console.log(item.id_type, idValue)
        return item.id_type === idValue
      })

      console.log(currentIds, getId)

      let getSelectedId = {}

      Object.keys(currentIds).forEach(function (key, index) {
        if (currentIds[key].id_type === idValue) {
          getSelectedId = currentIds[key]
        }
      })

      this.transactionData.id_number = getSelectedId.id_number
      this.transactionData.id_issue_date = getSelectedId.issue
      this.transactionData.id_expiration_date = getSelectedId.expiry
      this.transactionData.id_country_of_issue = getSelectedId.country
    },
    async populatePayload (data) {
      this.showForm = true
      // const that = this

      // setTimeout(function () {
      //   that.$nextTick(() => {
      //     that.$refs.receiveForm.scrollIntoView({ behavior: 'smooth' })
      //   })
      // }, 500)

      if (this.selectedTrxData.result === undefined) {
        this.selectedTrxData = this.currentTrx
      }

      const selectedTrxData = this.selectedTrxData.result
      const idList = data.img ? JSON.parse(data.img) : []

      let currentId = idList['1']
      currentId = idList['1'] ? idList['1'] : {}

      const currentUser = this.currUser
      const currentDate = format(parseISO(currentUser.trx_date), 'yyyy-MM-dd')
      const getRefNo = selectedTrxData.reference_number ? selectedTrxData.reference_number : (selectedTrxData.client_reference_no ? selectedTrxData.client_reference_no : '')
      const clientRefererenceConvert = CryptoJS.MD5(currentDate).toString()
      const clientRefererenceNo = clientRefererenceConvert.substr(0, 20)
      let referenceNumber = ''

      if (getRefNo !== '' && getRefNo !== undefined && getRefNo !== null) {
        referenceNumber = getRefNo
      } else {
        referenceNumber = this.payoutReferenceNo
      }

      this.currentIdFile = currentId.img

      let isDomestic = 0
      let currencyId = 1

      if (this.currentTrx.remco_id === 7) {
        await this.$store.dispatch('getOccupation', 7)
      } else if (this.currentTrx.remco_id === 20) {
        const that = this
        await this.$store.dispatch('nonex/getUnitellerOccupation').then(res => {
          if (res.data) {
            that.unitellerOccupation = res.data
          }
        })
      }

      if (selectedTrxData.originating_country === 'PH' ||
        selectedTrxData.originating_country === 'PHP' ||
        selectedTrxData.originating_country === 'Philippines' ||
        selectedTrxData.originating_country === 'PILIPINAS' ||
        selectedTrxData.originating_country === 'PHILIPPINES') {
        isDomestic = 1
      } else if (!selectedTrxData.originating_country) {
        isDomestic = 0
      } else {
        isDomestic = 0
      }

      if (selectedTrxData.currency === 'PHP') {
        currencyId = 1
      } else if (selectedTrxData.currency === 'USD') {
        currencyId = 2
      } else {
        currencyId = 1
      }

      const currentEndpoint = this.selectedTrxData.payout_route
      const updatedEndpoint = currentEndpoint.replace('/usp/v2/', '')

      this.transactionData = {
        location_code: currentUser.location_code,
        location_name: currentUser.location_name,
        nationality: data.nationality,
        endpoint: updatedEndpoint,
        beneficiary_id: selectedTrxData.beneficiary_id,
        location_id: currentUser.location_id,
        user_id: currentUser.user_id,
        trx_date: currentDate,
        currency: selectedTrxData.currency ? selectedTrxData.currency : 'PHP',
        currency_id: currencyId,
        remco_id: this.selectedTrxData.remco_id,
        remco_name: this.selectedTrxData.remco_name,
        trx_type: '2',
        is_domestic: isDomestic,
        control_number: selectedTrxData.control_number ? selectedTrxData.control_number : selectedTrxData.ref_no,
        client_reference_no: 'usp2' + clientRefererenceNo,
        customer_id: data.customer_id,
        customer_name: data.full_name,
        sender_name: selectedTrxData.sender_name,
        receiver_name: data.full_name,
        principal_amount: selectedTrxData.principal_amount,
        service_charge: 0,
        dst_amount: 0,
        total_amount: selectedTrxData.principal_amount,
        buy_back_amount: '0',
        mc_rate_id: '0',
        mc_rate: '0',
        rate_category: 0,
        originating_country: '',
        destination_country: 'PH',
        purpose_transaction: data.purpose_transaction,
        relation_to: data.relation_to,
        id_type: currentId.id_type ? currentId.id_type : '',
        id_number: currentId.id_number ? currentId.id_number : '',
        id_issued_by: currentId.country ? currentId.country : '',
        id_date_of_issue: currentId.issue ? currentId.issue : '',
        id_expiration_date: currentId.expiry ? currentId.expiry : '',
        form_type: 'OAR',
        form_number: '0',
        payout_type: 1,
        remote_location_id: currentUser.location_id,
        remote_user_id: currentUser.user_id,
        remote_ip_address: currentUser.ip_address,
        ip_address: currentUser.ip_address,
        reference_number: referenceNumber || clientRefererenceNo,
        birth_date: data.birth_date,
        risk_criteria: '{}',
        current_address: {
          address_1: '',
          address_2: '',
          barangay: '',
          city: '',
          province: '',
          zip_code: '',
          country: ''
        },
        permanent_address: {
          address_1: '',
          address_2: '',
          barangay: '',
          city: '',
          province: '',
          zip_code: '',
          country: ''
        }
      }

      let isIDCompatible = false

      await this.$store.dispatch('getIDList', this.selectedTrxData.remco_id).then((data) => {
        data.result.forEach(item => {
          console.log(item.id_type, currentId.id_type)
          if (item.id_type === currentId.id_type) {
            isIDCompatible = true
          }
        })
      })

      if (!isIDCompatible) {
        this.renderToast('warning', 'The Current ID selected is not on the Accepted ID List', 'The current ID presented is not on the accepted ID list. Please try another ID.')
      }
    },
    async confirmPayout () {
      const validate = this.$refs.form.validate()

      if (validate) {
        const currentCustomer = this.selectedCustomer
        const selectedTrxData = this.selectedTrxData.result

        const remcoId = this.selectedTrxData.remco_id
        const payoutAmt = this.transactionData.principal_amount
        let serviceFee = 0.00

        const payload = {
          remco_id: remcoId,
          payout_amount: payoutAmt
        }

        if (remcoId === 22) {
          await this.$store.dispatch('nonex/getRemcoServiceFee', payload).then(res => {
            const serviceFeeResult = res.result
            const getServiceCharge = serviceFeeResult.filter((serviceCharge) => {
              const minimumAmount = parseFloat(serviceCharge.minimum_amount)
              const maximumAmount = parseFloat(serviceCharge.maximum_amount)

              if (parseFloat(payoutAmt) >= minimumAmount && parseFloat(payoutAmt) <= maximumAmount) {
                return serviceCharge
              } else if (parseFloat(payoutAmt) > 50000) {
                return serviceCharge.maximum_amount === '50000.00'
              } else if (parseFloat(payoutAmt) < 1) {
                return '0'
              }
            })

            if (getServiceCharge[0] !== undefined) {
              serviceFee = parseFloat(getServiceCharge[0].service_fee)
            } else {
              serviceFee = parseFloat(0)
            }
          }).catch(e => {
            console.log(e)
          })
        } else {
          if (Object.prototype.hasOwnProperty.call(selectedTrxData, 'service_charge')) {
            serviceFee = selectedTrxData.service_charge
          } else {
            serviceFee = 0
          }
        }

        const updatedPayload = {
          destination_country: 'PH',
          source_fund: currentCustomer.source_fund,
          occupation: currentCustomer.occupation,
          birth_place: currentCustomer.birth_place,
          birth_country: currentCustomer.country_birth,
          address: currentCustomer.address,
          barangay: currentCustomer.barangay,
          city: currentCustomer.city1,
          province: currentCustomer.province,
          zip_code: currentCustomer.zip_code,
          country: currentCustomer.country,
          contact_number: currentCustomer.mobile_no,
          payout_type: 1,
          risk_criteria: '{}',
          service_charge: serviceFee,
          birth_date: currentCustomer.birth_date,
          id_type: this.transactionData.id_type ? this.transactionData.id_type : '',
          id_number: this.transactionData.id_number ? this.transactionData.id_number : '',
          id_issued_by: this.transactionData.id_issued_by ? this.transactionData.id_issued_by : '',
          id_date_of_issue: this.transactionData.id_date_of_issue ? this.transactionData.id_date_of_issue : '',
          id_expiration_date: this.transactionData.id_expiration_date ? this.transactionData.id_expiration_date : '',
          current_address: {
            address_1: currentCustomer.address,
            address_2: '',
            barangay: currentCustomer.barangay,
            city: currentCustomer.city1,
            province: currentCustomer.province,
            zip_code: currentCustomer.zip_code,
            country: currentCustomer.country
          },
          permanent_address: {
            address_1: currentCustomer.address,
            address_2: '',
            barangay: currentCustomer.barangay,
            city: currentCustomer.city1,
            province: currentCustomer.province,
            zip_code: currentCustomer.zip_code,
            country: currentCustomer.country
          }
        }

        let additionalPayload = {}
        this.transactionData = Object.assign({}, this.transactionData, updatedPayload)

        console.log(this.transactionData.remco_id)

        let receiverCityId = ''
        let receiverStateId = ''
        let receiverProvinceValue = ''
        let getCurrentPurpose = ''
        let getCurrentIdType = ''
        let getCurrentCountry = ''
        let getCurrentBirthCountry = ''
        let getCurrentNationality = ''
        let getCurrentOriginatingCountry = ''
        let getCurrentIdIssuedBy = ''
        let generateDeviceId = ''
        let senderNameData = ''
        let receiverNameData = ''
        let hasOwnIDList = false
        let isIDCompatible = false
        let getLocationId = ''
        let senderArrName = ''

        switch (this.transactionData.remco_id) {
          case 5:
            this.collections.countries.filter(data => {
              if (data.nationality === this.transactionData.nationality) {
                getCurrentNationality = data
              }

              if (data.country === this.transactionData.country) {
                getCurrentCountry = data
              }

              if (data.country === currentCustomer.country_birth) {
                getCurrentBirthCountry = data
              }

              if (data.country === this.transactionData.originating_country) {
                getCurrentOriginatingCountry = data
              }
            })

            generateDeviceId = `${this.currUser.user_id}${this.currUser.location_id}`

            additionalPayload = {
              agent_code: this.currUser.location_code,
              // agent_code: '01010701',
              agent_id: '84424911',
              gender: currentCustomer.gender === 'male' ? 'M' : 'F',
              device_id: Buffer.from(generateDeviceId).toString('base64'),
              nationality: getCurrentNationality.two_iso_code,
              receiver_first_name: currentCustomer.first_name,
              receiver_last_name: currentCustomer.last_name,
              country_of_origin: getCurrentCountry.two_iso_code,
              country: getCurrentCountry.two_iso_code,
              birth_country: getCurrentBirthCountry.two_iso_code,
              originating_country: getCurrentOriginatingCountry.two_iso_code
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 7:
            hasOwnIDList = true
            await this.$store.dispatch('nonex/getRemcoStateCities', { state: this.transactionData.province, city: this.transactionData.city, remcoId: this.selectedTrxData.remco_id }).then((data) => {
              receiverCityId = data.result.Id
              receiverStateId = data.result.StateId
            })

            await this.$store.dispatch('getIDList', this.selectedTrxData.remco_id).then((data) => {
              data.result.forEach(item => {
                if (item.id_type === this.transactionData.id_type) {
                  isIDCompatible = true
                  getCurrentIdType = item
                }
              })
            })

            this.collections.purposeOfTransaction.filter(data => {
              if (data.purpose === this.transactionData.purpose_transaction) {
                getCurrentPurpose = data
              }
            })

            additionalPayload = {
              id_type: getCurrentIdType.id_value,
              gender: currentCustomer.gender === 'male' ? 'M' : 'F',
              kyc_verified: true,
              location_name: this.currUser.location_name,
              proof_of_address_collected: 'True',
              receiver_city_id: receiverCityId,
              receiver_occupation_id: parseInt(this.transactionData.receiver_occupation_id_tf),
              receiver_state_id: receiverStateId,
              remittance_reason_id: parseInt(getCurrentPurpose.index)
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            console.log(this.transactionData)
            break
          case 9:
            hasOwnIDList = true

            await this.$store.dispatch('getIDList', this.selectedTrxData.remco_id).then((data) => {
              data.result.forEach(item => {
                if (item.id_type === this.transactionData.id_type) {
                  isIDCompatible = true
                  getCurrentIdType = item
                }
              })
            })

            additionalPayload = {
              identification_type_id: getCurrentIdType.id_value,
              agent_code: this.currUser.location_code
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 10:
          case 14:
          case 15:
            senderNameData = this.transactionData.sender_name.split(',')
            receiverNameData = this.transactionData.receiver_name.split(',')
            getLocationId = this.currUser.location_id.toString()
            senderArrName = senderNameData.map((name) => name.trim().toLowerCase())

            additionalPayload = {
              sender_first_name: senderNameData[1].trimStart(),
              sender_middle_name: (senderNameData[2] !== undefined) ? senderNameData[2].trimStart() : '',
              sender_last_name: senderNameData[0],
              receiver_first_name: receiverNameData[1].trimStart(),
              receiver_middle_name: (receiverNameData[2] !== undefined) ? receiverNameData[2].trimStart() : '',
              receiver_last_name: receiverNameData[0],
              branch_code: getLocationId.padStart(5, '0')
            }

            if (senderArrName.includes('dswd')) {
              additionalPayload.remco_id = 14
            } else if (senderArrName.includes('dole')) {
              additionalPayload.remco_id = 15
            } else {
              additionalPayload.remco_id = 10
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 12:
            this.collections.countries.filter(data => {
              if (data.country === this.transactionData.country) {
                getCurrentCountry = data
              }

              if (data.country === this.transactionData.id_issued_by) {
                getCurrentIdIssuedBy = data
              }

              if (data.country === this.transactionData.birth_country) {
                getCurrentBirthCountry = data
              }

              if (data.nationality === this.transactionData.nationality) {
                getCurrentNationality = data
              }

              if (data.country === this.transactionData.originating_country) {
                getCurrentOriginatingCountry = data
              }
            })

            generateDeviceId = `${this.currUser.user_id}${this.currUser.location_id}`

            additionalPayload = {
              agent_code: this.currUser.location_code,
              // agent_code: '01010701',
              agent_id: '84424911',
              gender: currentCustomer.gender === 'male' ? 'M' : 'F',
              device_id: Buffer.from(generateDeviceId).toString('base64'),
              order_number: this.selectedTrxData.result.order_number,
              birth_country: getCurrentBirthCountry.two_iso_code,
              nationality: getCurrentNationality.two_iso_code,
              country: getCurrentCountry.two_iso_code,
              id_issued_by: getCurrentIdIssuedBy.two_iso_code,
              client_reference_no: this.selectedTrxData.result.client_reference_no
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 19:
            hasOwnIDList = true

            await this.$store.dispatch('getIDList', this.selectedTrxData.remco_id).then((data) => {
              data.result.forEach(item => {
                if (item.id_type === this.transactionData.id_type) {
                  isIDCompatible = true
                  getCurrentIdType = item
                }
              })
            })

            additionalPayload = {
              identification_type_id: getCurrentIdType.id_value,
              id_issued_city: currentCustomer.city1,
              passport_id_issued_country: 166,
              international_partner_code: 'PL0005',
              agent_code: this.currUser.location_code
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 20:
            await this.$store.dispatch('nonex/getRemcoStateCities', { state: this.transactionData.province, city: this.transactionData.city, remcoId: this.selectedTrxData.remco_id }).then((data) => {
              receiverStateId = data.result.UTL_CODE
              receiverProvinceValue = data.result.STATE_NAME
            })

            await this.$store.dispatch('getIDList', this.selectedTrxData.remco_id).then((data) => {
              data.result.forEach(item => {
                if (item.id_type === this.transactionData.id_type) {
                  isIDCompatible = true
                  getCurrentIdType = item
                }
              })
            })

            this.collections.countries.filter(data => {
              if (data.country === this.transactionData.country) {
                getCurrentCountry = data
              }

              if (data.country === this.transactionData.birth_country) {
                getCurrentBirthCountry = data
              }

              if (data.nationality === this.transactionData.nationality) {
                getCurrentNationality = data
              }

              if (data.country === this.transactionData.originating_country) {
                getCurrentOriginatingCountry = data
              }

              if (data.country === this.transactionData.id_issued_by) {
                getCurrentIdIssuedBy = data
              }
            })

            additionalPayload = {
              state: receiverStateId,
              province: receiverProvinceValue,
              birth_country: getCurrentBirthCountry.two_iso_code,
              nationality: getCurrentNationality.two_iso_code,
              country: getCurrentCountry.two_iso_code,
              originating_country: getCurrentOriginatingCountry.two_iso_code,
              location_code: this.currUser.location_code,
              id_issued_by: getCurrentIdIssuedBy.two_iso_code,
              id_type: getCurrentIdType.id_value,
              occupation: this.transactionData.receiver_occupation_id
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 21:
            hasOwnIDList = true

            await this.$store.dispatch('getIDList', this.selectedTrxData.remco_id).then((data) => {
              data.result.forEach(item => {
                if (item.id_type === this.transactionData.id_type) {
                  isIDCompatible = true
                  getCurrentIdType = item
                }
              })
            })

            this.collections.purposeOfTransaction.filter(data => {
              if (data.purpose === this.transactionData.purpose_transaction) {
                getCurrentPurpose = data
              }
            })

            additionalPayload = {
              id_type: getCurrentIdType.id_value
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            console.log(this.transactionData)
            break
          case 22:
            hasOwnIDList = true

            await this.$store.dispatch('getIDList', this.selectedTrxData.remco_id).then((data) => {
              data.result.forEach(item => {
                if (item.id_type === this.transactionData.id_type) {
                  isIDCompatible = true
                  getCurrentIdType = item
                }
              })
            })

            additionalPayload = {
              id_type: getCurrentIdType.id_value
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 26:
            additionalPayload = {
              receiver_first_name: currentCustomer.first_name,
              receiver_last_name: currentCustomer.last_name
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
          case 27:
            additionalPayload = {
              receipt_number: this.transactionData.reference_number,
              principal_amount: this.transactionData.principal_amount.toString()
            }

            this.transactionData = Object.assign({}, this.transactionData, additionalPayload)
            break
        }

        console.log(this.transactionData)

        if (hasOwnIDList === false || (hasOwnIDList === true && isIDCompatible === true)) {
          this.showConfirmDialog = true
        } else {
          this.renderToast('error', 'The ID is not on the Accepted ID List', 'The current ID selected is not on the accepted ID list. Please try another ID.')
        }
      } else {
        this.renderToast('error', 'Error', 'The fields must be completed in order to payout')
      }
    },
    getStoragePath (value) {
      if (value.usp2 === 'true') {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_BASE_URL)}/${this.selectedCustomer.customer_id}/${value.img}`
      } else {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_USP1_URL)}/${this.selectedCustomer.customer_id}/${value.img}`
      }
    }
  }

}
</script>
<style scoped>
  .ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 160px;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
  }

  .ph-nonex-receive-action-buttons {
    display: flex;
    padding: 20px;
  }

  .ph-nonex-fields-container {
    padding: 12px
  }

  .v-image.ph-nonex-receive-id {
    max-width: 270px !important;
    margin: 0 auto;
    border-radius: 12px !important;
  }

  @media only screen and (min-width: 600px) {
    .v-image.ph-nonex-receive-id {
      max-width: 470px !important;
    }
  }
</style>
