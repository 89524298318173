<template>
  <div class="px-md-4 mt-3">
    <TransactionProfileCard />
    <strong>Select Insurance</strong>
    <v-row class="mt-md-3 mb-md-5" no-gutters>
      <v-col
        v-for="insurance in insuranceList"
        :key="insurance.category"
        class="py-1 py-md-0 pr-md-2 pb-md-2"
        :cols="12"
        :sm="6"
        :md="4"
      >
        <v-card
          @click="selectInsurance(insurance.category)"
          :class="['rounded-lg py-1 selection', { 'selected': transactionData.insurance.category === insurance.category }]"
          outlined
          flat
        >
          <v-card-text>
            <v-row align="center" no-gutters>
              <v-col class="d-flex flex-column align-center" :cols="12">
                <img
                  v-if="insurance.logo"
                  style="width: 100%;max-height: 60px;"
                  :src="require(`@/assets/images/insurance/${insurance.logo}`)"
                  :alt="insurance.name"
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-form ref="insurance">
      <v-row v-if="transactionData.insurance && transactionData.insurance.category !== 'COC7'" class="mt-6 mb-md-5" no-gutters>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.policy"
            :items="policies"
            item-text="policy_name"
            item-value="policy_name"
            class="ph-textbox"
            label="Policy Name *"
            return-object
            :readonly="!transactionData.insurance"
            :rules="requiredRules"
            @change="updateTransactionDetails"
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-menu
            v-model="inceptionDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="transactionData.inceptionDate"
                class="ph-textbox"
                label="Date of Effectivity"
                :rules="inceptionDateRules"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
              />
            </template>
            <v-date-picker
              v-model="transactionData.inceptionDate"
              :min="minDate"
              :max="maxDate"
              @input="inceptionDatePicker = false"
              @change="updateTransactionDetails"
            />
          </v-menu>
        </v-col>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.amount"
            class="ph-textbox"
            label="Amount to Collect"
            :rules="requiredRules"
            readonly
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.expiryDate"
            class="ph-textbox"
            label="Date of Expiry"
            :rules="requiredRules"
            readonly
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance && transactionData.insurance.category === 'COC8'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.agentPrefix"
            :items="this.agentPrefixList"
            class="ph-textbox"
            label="Agent Prefix *"
            return-object
            :readonly="!transactionData.insurance"
            :rules="requiredRules"
            @change="updateTransactionDetails"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance && transactionData.insurance.category === 'COC8'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.agentCode"
            class="ph-textbox"
            label="Agent Code"
            :rules="noWhiteSpacesRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance && transactionData.insurance.category === 'COC8'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.rsoPrefix"
            :items="this.rsoPrefixList"
            class="ph-textbox"
            label="RSO/ROH Prefix *"
            return-object
            :rules="requiredRules"
            @change="updateTransactionDetails"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance && transactionData.insurance.category === 'COC8'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.rsoCode"
            class="ph-textbox"
            label="RSO/ROH Code"
            :rules="noWhiteSpacesRules"
            outlined
          />
        </v-col>
      </v-row>
      <v-row v-if="transactionData.insurance && transactionData.insurance.category === 'COC7'" class="mt-6 mb-md-5" no-gutters>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.mvType"
            @change="onMVTypeChange"
            :items="ctplList"
            item-text="mv_type_name"
            item-value="mv_type"
            class="ph-textbox"
            label="MV type *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.premiumType"
            @change="onPremiumTypeChange"
            :items="filteredPremiumType"
            item-text="premium_type_name"
            item-value="premium_type"
            class="ph-textbox"
            label="Premium type *"
            :rules="requiredRules"
            :disabled="!transactionData.mvType"
            outlined
          />
        </v-col>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.plateNumber"
            v-if="!transactionData.noPlateNumber"
            @blur="displayInceptionExpiryDate"
            @input="toUpperCase('plateNumber')"
            :rules="plateNumberRules"
            class="ph-textbox"
            label="Plate Number *"
            outlined
          />
          <v-text-field
            v-model="transactionData.ORCRMonth"
            v-if="transactionData.noPlateNumber"
            @blur="displayInceptionExpiryDate"
            class="ph-textbox"
            type="number"
            label="OR/CR Month *"
            :rules="ORCRMonthRules"
            hide-spin-buttons
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.noPlateNumber"
              @change="onNoPlateNumberChange"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Plate Number"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.amount"
            class="ph-textbox"
            label="Amount to Collect"
            :rules="requiredRules"
            :disabled="!transactionData.mvType || !transactionData.premiumType"
            readonly
            outlined
          />
        </v-col>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.inceptionDate"
            class="ph-textbox"
            label="Date of Effectivity"
            :rules="requiredRules"
            :disabled="!transactionData.plateNumber"
            readonly
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.expiryDate"
            class="ph-textbox"
            label="Date of Expiry"
            :rules="requiredRules"
            :disabled="!transactionData.plateNumber"
            readonly
            outlined
          />
        </v-col>
      </v-row>
    </v-form>
    <div class="mt-6 d-flex justify-space-between">
      <v-btn
        @click="cancelDialog = true"
        class="rounded-lg font-weight-bold"
        color="primary"
        outlined
        large
      >
        Cancel
      </v-btn>
      <v-btn
        @click="next"
        class="rounded-lg font-weight-bold"
        :disabled="!transactionData.insurance"
        color="primary"
        large
      >
        Next
      </v-btn>
    </div>
    <v-dialog
      v-model="cancelDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Discard Transaction
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to discard this transaction?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="cancelDialog = false"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="cancel"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import {
  format,
  addDays,
  subDays,
  addMonths,
  addYears,
  isAfter,
  isBefore,
  parse
} from 'date-fns'
import {
  toMoney,
  renderToast,
  toProperCase,
  avatarColor,
  avatarInitial
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      agentPrefixList: 'insurance/agentPrefixList',
      rsoPrefixList: 'insurance/rsoPrefixList',
      insuranceList: 'insurance/insuranceList',
      policies: 'insurance/policies',
      transactionData: 'insurance/transactionData',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      ctplList: 'insurance/ctplList'
    }),
    selectedPolicy: {
      get () {
        return this.$store.state.insurance.selectedPolicy
      },
      set (val) {
        this.$store.commit('insurance/SET_SELECTED_POLICY', val)
      }
    },
    filteredPremiumType () {
      if (this.transactionData.mvType) {
        return this.ctplList.filter(data => data.mv_type === this.transactionData.mvType)
      } else {
        return []
      }
    },
    minDate () {
      return format(addDays(parse(this.currUser.trx_date, 'yyyy-MM-dd', new Date()), 1), 'yyyy-MM-dd')
    },
    maxDate () {
      return format(addYears(addDays(parse(this.currUser.trx_date, 'yyyy-MM-dd', new Date()), 1), 1), 'yyyy-MM-dd')
    }
  },
  components: {
    TransactionProfileCard
  },
  data () {
    return {
      inceptionDatePicker: false,
      cancelDialog: false,
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      inceptionDateRules: [
        v => !!v || 'This field is required',
        v => this.isValidInceptionDate(v) || 'The date of effectivity is invalid'
      ],
      plateNumberRules: [
        v => !!v || 'This field is required'
      ],
      ORCRMonthRules: [
        v => !!v || 'This field is required',
        v => (v.length === 2) || 'Must be 2 characters'
      ],
      noWhiteSpacesRules: [
        v => !!v || 'This field is required',
        v => !/\s/.test(v) || 'No whitespace allowed'
      ]
    }
  },
  methods: {
    isValidInceptionDate (data) {
      const givenDate = parse(data, 'yyyy-MM-dd', new Date())
      const minDate = subDays(parse(this.minDate, 'yyyy-MM-dd', new Date()), 1)
      const maxDate = addDays(parse(this.maxDate, 'yyyy-MM-dd', new Date()), 1)
      return isAfter(givenDate, minDate) && isBefore(givenDate, maxDate)
    },
    isValidPlateNumber (data) {
      return (/^([a-zA-Z]){3}\d{2}$/.test(data) ||
        /^([a-zA-Z]){3}\d{3}$/.test(data) ||
        /^([a-zA-Z]){3}\d{4}$/.test(data) ||
        /^([a-zA-Z]){2}\d{4}$/.test(data) ||
        /^([a-zA-Z]){2}\d{5}$/.test(data) ||
        /^([a-zA-Z]){3}\d{5}$/.test(data) ||
        /^\d{3}([a-zA-Z]){3}$/.test(data))
    },
    toUpperCase (prop) {
      this.transactionData[prop] = this.transactionData[prop].toUpperCase()
    },
    displayInceptionExpiryDate () {
      if ((!this.transactionData.noPlateNumber && this.transactionData.plateNumber) || (this.transactionData.noPlateNumber && this.transactionData.ORCRMonth)) {
        const digit = this.transactionData.plateNumber.replace(/[^0-9]/g, '') || this.transactionData.ORCRMonth
        const date = parse(this.currUser.trx_date, 'yyyy-MM-dd', new Date())
        date.setDate = 1

        if (digit) {
          if (this.transactionData.noPlateNumber) {
            date.setMonth(parseInt(digit.charAt(digit.length - 1)) - 1)
          } else {
            date.setMonth(parseInt(digit.charAt(digit.length - 1)))
          }

          const expiryDate = addYears(date, 1)

          this.transactionData.inceptionDate = format(date, 'yyyy-MM-dd')
          this.transactionData.expiryDate = format(expiryDate, 'yyyy-MM-dd')
        } else {
          this.transactionData.inceptionDate = ''
          this.transactionData.expiryDate = ''
        }
      } else {
        this.transactionData.inceptionDate = ''
        this.transactionData.expiryDate = ''
      }
    },
    onNoPlateNumberChange () {
      this.$store.commit('insurance/SET_TRANSACTION_DATA', { prop: 'plateNumber', value: '' })
      this.$store.commit('insurance/SET_TRANSACTION_DATA', { prop: 'ORCRMonth', value: '' })
      this.transactionData.inceptionDate = ''
      this.transactionData.expiryDate = ''
      this.$refs.insurance.resetValidation()
    },
    onPremiumTypeChange (value) {
      const selectedPremium = this.filteredPremiumType.filter(data => data.premium_type === value)

      if (selectedPremium.length === 1) {
        this.$store.commit('insurance/SET_SELECTED_PREMIUM', selectedPremium[0])

        if (selectedPremium[0].approver_status === 'Approve') {
          const newValues = [
            { prop: 'amount', value: selectedPremium[0].total_premium },
            { prop: 'vehicleAuthenticationFee', value: selectedPremium[0].authentication_fee },
            { prop: 'vehicleBasicPremium', value: selectedPremium[0].basic_premium },
            { prop: 'vehicleDocStamps', value: selectedPremium[0].doc_stamps },
            { prop: 'vehicleCancellationFee', value: selectedPremium[0].cancellation_fee },
            { prop: 'vehicleVAT', value: selectedPremium[0].vat },
            { prop: 'vehicleLGT', value: selectedPremium[0].lgt },
            { prop: 'vehicleTotalCommission', value: selectedPremium[0].total_commission },
            { prop: 'vehiclePetnetCommission', value: selectedPremium[0].petnet_commission },
            { prop: 'vehicleBPCommission', value: selectedPremium[0].bp_commission },
            { prop: 'vehicleDiscountedPrice', value: selectedPremium[0].discounted_price },
            { prop: 'vehiclePromoCode', value: selectedPremium[0].promo_code }
          ]
          newValues.map(payload => this.$store.commit('insurance/SET_TRANSACTION_DATA', payload))
        } else {
          const newValues = [
            { prop: 'amount', value: selectedPremium[0].previous_total_premium },
            { prop: 'vehicleAuthenticationFee', value: selectedPremium[0].previous_authentication_fee },
            { prop: 'vehicleBasicPremium', value: selectedPremium[0].previous_basic_premium },
            { prop: 'vehicleDocStamps', value: selectedPremium[0].previous_doc_stamps },
            { prop: 'vehicleCancellationFee', value: selectedPremium[0].previous_cancellation_fee },
            { prop: 'vehicleVAT', value: selectedPremium[0].previous_vat },
            { prop: 'vehicleLGT', value: selectedPremium[0].previous_lgt },
            { prop: 'vehicleTotalCommission', value: selectedPremium[0].previous_total_commission },
            { prop: 'vehiclePetnetCommission', value: selectedPremium[0].previous_petnet_commission },
            { prop: 'vehicleBPCommission', value: selectedPremium[0].previous_bp_commission },
            { prop: 'vehicleDiscountedPrice', value: selectedPremium[0].previous_discounted_price },
            { prop: 'vehiclePromoCode', value: selectedPremium[0].previous_promo_code }
          ]
          newValues.map(payload => this.$store.commit('insurance/SET_TRANSACTION_DATA', payload))
        }
      } else {
        this.$store.commit('insurance/SET_SELECTED_PREMIUM', null)
      }
    },
    onMVTypeChange () {
      this.$store.commit('insurance/SET_TRANSACTION_DATA', { prop: 'amount', value: '' })
      this.$store.commit('insurance/SET_TRANSACTION_DATA', { prop: 'premiumType', value: '' })
      this.$store.commit('insurance/SET_SELECTED_PREMIUM', null)
      this.$refs.insurance.resetValidation()
    },
    cancel () {
      this.$store.dispatch('insurance/resetForm')
      this.$router.push({ path: '/pera-protect' })
    },
    next () {
      const { insurance } = this.transactionData

      if (this.$refs.insurance.validate()) {
        if (insurance.category === 'COC3' || insurance.category === 'COC4' || insurance.category === 'COC8') this.doValidate()
        else this.$store.commit('insurance/SET_CURRENT_STEP', 2)
      }
    },
    async doValidate () {
      const payload = {
        firstName: this.selectedCustomer.first_name,
        lastName: this.selectedCustomer.last_name,
        birthDate: this.selectedCustomer.birth_date,
        category: this.transactionData.insurance.category,
        inceptionDate: this.transactionData.inceptionDate,
        expiryDate: this.transactionData.expiryDate
      }

      const req = await this.$store.dispatch('insurance/validate', payload)

      if (req.code === 200) {
        if (req.result.length > 0) {
          const ongoingPolicyExpiry = parse(this.transactionData.expiryDate, 'yyyy-MM-dd', new Date())
          const lastPolicyExpiry = parse(req.result[0].expirydate, 'yyyy-MM-dd', new Date())

          if (isAfter(ongoingPolicyExpiry, lastPolicyExpiry)) {
            this.$store.commit('insurance/SET_CURRENT_STEP', 2)
          } else {
            this.renderToast('error', 'Invalid transaction', 'The customer already have an existing policy')
          }
        } else {
          this.$store.commit('insurance/SET_CURRENT_STEP', 2)
        }
      } else {
        this.renderToast('error', 'Pera Protect Validate', 'Failed to validate pera protect transaction')
      }
    },
    updateTransactionDetails () {
      const { insurance } = this.transactionData

      if (this.transactionData.policy) {
        const policyDetail = this.policies.filter(policy => policy.policy_name === this.transactionData.policy.policy_name)
        let amount = 0
        let expiryDate = ''
        let policyType = ''

        if (insurance.category === 'COC') {
          if (policyDetail.length === 1) {
            amount = policyDetail[0].policy_face_value
            policyType = policyDetail[0].policy_type
          }

          expiryDate = format(addMonths(parse(this.transactionData.inceptionDate, 'yyyy-MM-dd', new Date()), Number(this.transactionData.policy.month_duration)), 'yyyy-MM-dd')
        } else {
          if (policyDetail.length === 1) amount = policyDetail[0].price

          expiryDate = format(addMonths(parse(this.transactionData.inceptionDate, 'yyyy-MM-dd', new Date()), Number(this.transactionData.policy.product_type)), 'yyyy-MM-dd')
        }

        console.log(amount)

        this.$store.commit('insurance/SET_TRANSACTION_DATA', {
          prop: 'expiryDate',
          value: expiryDate
        })

        this.$store.commit('insurance/SET_TRANSACTION_DATA', {
          prop: 'amount',
          value: amount
        })

        this.$store.commit('insurance/SET_TRANSACTION_DATA', {
          prop: 'policyType',
          value: policyType
        })
      }
    },
    async selectInsurance (category) {
      let dateTomorrow = ''
      const insuranceDetails = this.insuranceList.filter(insurance => insurance.category === category)
      let selectedInsurance = ''

      this.$store.commit('insurance/SET_POLICIES', [])

      if (insuranceDetails.length === 1) selectedInsurance = insuranceDetails[0]

      if (category === 'COC7') {
        const getCTPLList = await this.$store.dispatch('insurance/getCTPLList')
        if (getCTPLList.code === 200) this.$store.commit('insurance/SET_CTPL_LIST', getCTPLList.result)
        else this.$store.commit('insurance/SET_CTPL_LIST', [])

        const newTransactionData = [
          { prop: 'inceptionDate', value: dateTomorrow },
          { prop: 'insurance', value: selectedInsurance },
          { prop: 'mvType', value: '' },
          { prop: 'premiumType', value: '' },
          { prop: 'amount', value: '' },
          { prop: 'issueDate', value: '' },
          { prop: 'expiryDate', value: '' }
        ]

        newTransactionData.map(data => this.$store.commit('insurance/SET_TRANSACTION_DATA', data))
      } else {
        let getPolicies = []

        if (category === 'COC') {
          getPolicies = await this.$store.dispatch('insurance/getAccidentPlusPolicies')
        } else {
          getPolicies = await this.$store.dispatch('insurance/getPolicies', { category })
        }

        dateTomorrow = format(addDays(parse(this.currUser.trx_date, 'yyyy-MM-dd', new Date()), 1), 'yyyy-MM-dd')

        if (getPolicies.code === 200) this.$store.commit('insurance/SET_POLICIES', getPolicies.result)

        const newTransactionData = [
          { prop: 'inceptionDate', value: dateTomorrow },
          { prop: 'insurance', value: selectedInsurance },
          { prop: 'policy', value: '' },
          { prop: 'amount', value: '' },
          { prop: 'expiryDate', value: '' }
        ]

        newTransactionData.map(data => this.$store.commit('insurance/SET_TRANSACTION_DATA', data))
      }

      this.$refs.insurance.resetValidation()
    },

    toMoney,
    renderToast,
    toProperCase,
    avatarColor,
    avatarInitial
  },
  mounted () {
    if (!this.transactionData.insurance) {
      this.selectInsurance('COC')
    }
  }
}
</script>
