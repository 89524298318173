export default {
  SET_TRANSACTIONS (state, payload) {
    state.transactions = payload
  },
  SET_INQUIRE_RESULT (state, payload) {
    state.inquireResult = payload
  },
  SET_REMCO_LIST_RESULT (state, payload) {
    state.remcos = payload
  },
  SET_CURRENT_TRANSACTION (state, payload) {
    state.currentTrx = payload
  },
  SET_SENDOUT_CONFIRM_DIALOG (state, payload) {
    state.sendoutConfirmDialog = payload
  },
  SET_SENDOUT_OTP_DIALOG (state, payload) {
    state.sendoutOTPDialog = payload
  },
  SET_SENDOUT_SERVICE_FEES (state, payload) {
    state.sendoutServiceFees = payload
  },
  SET_REFERENCE_NUMBER (state, payload) {
    state.payoutReferenceNo = payload
  },
  SET_IS_GCASH_TRX (state, payload) {
    state.isGCashTrx = payload
  }
}
