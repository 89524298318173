<template>
  <div>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-md-1 mx-md-4 mt-md-5" no-gutters>
      <v-col cols="12" v-if="showRemoteNotice">
        <v-alert
          color="info"
          dark
          icon="mdi-remote"
          border="left"
          prominent
        >
          This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
        </v-alert>
      </v-col>
      <v-col :cols="12" :md="4">
        <h3 class="pb-2 pb-md-6 custom-heading primary--text">
          Customer Lookup
        </h3>
        <p class="text-body-2 mb-6">
          Search for a PERA Hub customer or click New Customer for first-time transaction
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-4 mb-md-5 grey--text text--darken-2">
          Customer's name
        </h4>
        <v-text-field
          v-model="firstName"
          class="ph-textbox mb-4 mb-md-6"
          label="First Name"
          @input="convertToProperCase('firstName')"
          @keyup.enter="doSearch"
          outlined
        />
        <v-text-field
          v-model="lastName"
          class="ph-textbox mb-4 mb-md-6"
          label="Last Name"
          @input="convertToProperCase('lastName')"
          @keyup.enter="doSearch"
          outlined
        />
        <div class="text-right">
          <v-btn @click="doSearch" :disabled="!firstName || !lastName" class="rounded-lg" color="primary" large>
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
          <div class="d-flex align-center">
            <span>Can't find customer here?</span>
            <v-btn
              @click="addNewCustomer"
              color="primary"
              x-small
              text
            >
              Add New Customer
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  trim,
  renderToast,
  customerRiskAssessment
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      searchData: 'customers/searchData',
      remoteData: 'obo/remoteData'
    })
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      resultDialog: false,
      searchText: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ],
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Palit',
          disabled: false,
          href: '/pera-palit'
        },
        {
          text: 'Customer Lookup',
          disabled: true
        }
      ]
    }
  },
  methods: {
    renderToast,
    addNewCustomer () {
      this.$router.push({
        path: '/customers/new',
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    async selectCustomer (customerDetails) {
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
      if (this.currUser.cisrat === 1) {
        this.resultDialog = false
        this.searchData.results = []

        const { code, data } = await customerRiskAssessment(customerDetails)

        if (code === 'FOUND_HIGH_RISK_GO' || code === 'FOUND_HIGH_RISK_STOP') {
          this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
            isShow: true,
            type: code,
            details: data,
            customer: customerDetails
          })
          return true
        }

        if (code === 'ERR_INTERNAL_CATCH') {
          renderToast('error', 'USP Error', 'An error encountered while checking the customer')
          return true
        }

        if (code === 'ERROR_CODE') {
          renderToast('error', `[${data.code}] Error`, 'An error encountered while checking the customer')
          return true
        }

        if (code === 'ERR_NO_API_RESPONSE') {
          renderToast('error', 'USP Error', 'Invalid API response')
          return true
        }

        if (code === 'FOUND_NEGATIVE_LIST') {
          if (customerDetails.is_negative_list !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }

        if (code === 'FOUND_ADVERSE') {
          if (customerDetails.is_fraud !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }

        if (code === 'FOUND_PEP') {
          if (customerDetails.is_pep !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }
      }

      this.proceedToTransaction()
    },
    proceedToTransaction () {
      this.$store.commit('moneyChange/SET_CURRENT_PAGE', 1)
    },
    doSearch () {
      if (this.firstName && this.lastName) {
        const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
        const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword)
        this.resultDialog = true
      } else {
        this.searchData.results = []
      }
    },
    toProperCase,
    avatarColor,
    avatarInitial
  },
  mounted () {
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
    this.$store.commit('SET_PAGE_TITLE', 'PERA Palit')

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id

      this.showRemoteNotice = true

      this.renderToast('info', 'Remote Transaction is Enabled', `This transaction will be processed on behalf of ${this.remoteData?.remote_location?.location_name} branch.`)
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id

      this.showRemoteNotice = false

      this.renderToast('warning', 'Remote Transaction data cannot be retrived', 'The remote transaction data cannot be found. Normal transaction settings will be applied for this transaction instead.')
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id

      this.showRemoteNotice = false
    }
  }
}
</script>
